.App {
  text-align: center;
}

.gradient {
  background: linear-gradient(90deg, #36363e 0%, #302f35 100%);
}

.modal {
  transform: translateX(0);
}

.mapboxgl-control-container {
  display: none !important;
}

.rotate-180 {
  transform: rotate(90deg);
}

.map-min {
  min-height: 20rem;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.zig-zag {
  background: linear-gradient(-45deg, transparent 16px, #f0f0f0 0),
    linear-gradient(45deg, transparent 16px, #f0f0f0 0);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 22px 32px;
  content: "";
  display: block;

  width: 100%;
  height: 32px;
  margin: 32px 0;
  margin-bottom: 0;
  position: relative;
  top: 64px;
  left: 0px;
}
